var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { useEffect, useRef, useState } from 'react';
import { useResize } from '../../common/hooks/resize';
var OTHER_BUTTON_WIDTH = 70;
export var useWrapItems = function (items, wrapOthers) {
    var containerRef = useRef(null);
    var itemsRef = useRef([]);
    var itemsWidth = useRef([]);
    var _a = __read(useState(items.length), 2), visibleItemsCount = _a[0], setVisibleItemsCount = _a[1];
    var handleResize = function () {
        var e_1, _a;
        var container = containerRef.current;
        if (!wrapOthers || !container || !itemsWidth.current) {
            return;
        }
        var containerWidth = container.offsetWidth;
        var _b = window.getComputedStyle(container), paddingLeft = _b.paddingLeft, paddingRight = _b.paddingRight;
        var containerPaddings = parseFloat(paddingLeft) + parseFloat(paddingRight);
        var totalItemsWidth = 0;
        var count = 0;
        try {
            for (var _c = __values(itemsWidth.current), _d = _c.next(); !_d.done; _d = _c.next()) {
                var item = _d.value;
                totalItemsWidth += item;
                if (totalItemsWidth > containerWidth - containerPaddings - OTHER_BUTTON_WIDTH) {
                    break;
                }
                count++;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        setVisibleItemsCount(count);
    };
    useEffect(function () {
        // Save all items width, to not lose it after unmounting remaining ones
        itemsWidth.current = itemsRef.current.map(function (item) {
            var width = item.offsetWidth;
            var _a = window.getComputedStyle(item), marginLeft = _a.marginLeft, marginRight = _a.marginRight;
            return width + parseFloat(marginLeft) + parseFloat(marginRight);
        });
        handleResize();
    }, []);
    useResize(handleResize);
    useEffect(function () {
        itemsRef.current = itemsRef.current.slice(0, items.length);
    }, [items]);
    return { visibleItemsCount: visibleItemsCount, containerRef: containerRef, itemsRef: itemsRef };
};
