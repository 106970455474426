var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, background, border, display, fontWeight, height, justifyContent, marginLeft, overflow, size, space, maxWidth as systemMaxWidth, themeGet, width, } from 'styled-system';
import Icon from '../../common/components/Icon';
import { Link } from '../../common/components/Link';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { modifiers as systemModifiers } from '../../common/theme/helper';
import { boxSizing, rotate, visibility, whiteSpace } from '../../common/theme/system-utilities';
import { Element, NavigationParent } from '../../common/types/tracking';
import { openSideNav, SideNavType } from '../../common/utils/navigation';
import { useWrapItems } from '../hooks/wrap-items';
import { trackNavigationItemClick } from '../utils/tracking';
var Container = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), boxSizing, display, visibility, overflow, height, justifyContent, space, systemMaxWidth, width, whiteSpace);
var containerStyles = {
    display: 'flex',
    visibility: ['hidden', null, 'visible'],
    overflow: ['hidden', null, 'visible'],
    height: [0, null, 'auto'],
    boxSizing: 'border-box',
    maxWidth: 0,
    mx: 'auto',
    px: [null, null, 'sp_48', 'sp_64'],
    width: [0, null, '100%'],
};
var NavigationButton = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:last-child {\n    margin-right: 0;\n  }\n  text-decoration: none !important;\n\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:last-child {\n    margin-right: 0;\n  }\n  text-decoration: none !important;\n\n  ", ";\n"])), background, border, display, alignItems, fontWeight, space, systemModifiers, function (props) { return ({
    '&:hover': {
        paddingBottom: themeGet('space.sp_12')(props) - 3,
        borderBottomStyle: 'solid',
        borderBottomWidth: themeGet('radii.small')(props),
        borderBottomColor: themeGet('colors.sys.primary.border.default')(props),
    },
}); });
var buttonStyles = {
    role: 'menuitem',
    background: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'baseline',
    fontSize: 'fs_16',
    fontWeight: 'bold',
    lineHeight: 'lh_20',
    m: 'sp_0',
    p: 'sp_0',
    py: 'sp_12',
};
var isLink = function (href) { return typeof href === 'string' && href.length > 0; };
var RightArrow = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), size, rotate, marginLeft);
var rightArrowStyles = {
    name: 'arrowDownStrong',
    size: 12,
    rotate: '-90',
    ml: 'sp_8',
};
var getNavigationParentComponent = function (sideNavType) {
    return sideNavType === SideNavType.Global
        ? NavigationParent.GlobalHeader
        : NavigationParent.ShopInShopNav;
};
export var PrimaryNavigation = function (_a) {
    var items = _a.items, withArrows = _a.withArrows, wrapItems = _a.wrapItems, sideNavType = _a.sideNavType, shopName = _a.shopName;
    var t = useLocalization().t;
    var _b = useWrapItems(items, wrapItems), visibleItemsCount = _b.visibleItemsCount, containerRef = _b.containerRef, itemsRef = _b.itemsRef;
    return (React.createElement(Container, __assign({ role: "menubar", "aria-label": t('h24_top_navigation_label'), "data-testid": "top-navigation", ref: containerRef }, containerStyles, { justifyContent: wrapItems ? 'flex-start' : 'space-between' }, (wrapItems && { whiteSpace: 'nowrap' })),
        items.slice(0, visibleItemsCount).map(function (_a, index) {
            var name = _a.name, modifiers = _a.modifiers, href = _a.href;
            var trackClick = function () {
                return trackNavigationItemClick({
                    parentComponent: getNavigationParentComponent(sideNavType),
                    element: isLink(href) ? Element.Link : Element.Button,
                    label: name,
                    length: items.length,
                    position: index,
                    shopName: shopName,
                });
            };
            var navigationProps = isLink(href)
                ? { href: href, onClick: trackClick }
                : {
                    as: 'button',
                    onClick: function () {
                        openSideNav({ selectedItem: name, type: sideNavType });
                        trackClick();
                    },
                };
            return (React.createElement(NavigationButton, __assign({ key: name, ref: function (ref) { return (itemsRef.current[index] = ref); }, modifiers: modifiers, mr: wrapItems ? 'sp_24' : 'sp_4' }, buttonStyles, navigationProps),
                name,
                withArrows && href === null && React.createElement(RightArrow, __assign({}, rightArrowStyles))));
        }),
        wrapItems && visibleItemsCount < items.length && (React.createElement(NavigationButton, __assign({ onClick: function () {
                openSideNav({ type: sideNavType });
                trackNavigationItemClick({
                    parentComponent: getNavigationParentComponent(sideNavType),
                    element: Element.Button,
                    label: t('h24_top_navigation_other'),
                    length: items.length,
                    position: visibleItemsCount,
                    shopName: shopName,
                });
            }, mr: "sp_16" }, buttonStyles),
            t('h24_top_navigation_other'),
            React.createElement(RightArrow, __assign({}, rightArrowStyles))))));
};
var templateObject_1, templateObject_2, templateObject_3;
