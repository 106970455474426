var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import { breakpoints as themeBreakpoints } from '../theme/theme';
import { isClient } from '../utils/isClient';
import { getInteger } from '../utils/number';
var breakpoints = themeBreakpoints.map(getInteger);
var THROTTLE_WAIT = 250;
var getBreakpoint = function (width) {
    var _a = __read(breakpoints, 3), tablet = _a[0], desktop = _a[1], largeDesktop = _a[2];
    if (width < tablet) {
        return 0;
    }
    else if (width >= tablet && width < desktop) {
        return tablet;
    }
    else if (width >= desktop && width < largeDesktop) {
        return desktop;
    }
    return largeDesktop;
};
var isBreakpointChange = function (currentWidth, nextWidth) {
    var currentBreakpoint = getBreakpoint(currentWidth);
    var nextBreakpoint = getBreakpoint(nextWidth);
    return currentBreakpoint !== nextBreakpoint;
};
export var useResizeOnBreakpoint = function (handler) {
    var width = useRef(isClient() ? window.innerWidth : null);
    var handleResize = throttle(function () {
        if (isBreakpointChange(width.current, window.innerWidth)) {
            width.current = window.innerWidth;
            handler();
        }
    }, THROTTLE_WAIT);
    useEffect(function () {
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, [handler]);
};
export var useResize = function (handler) {
    var handleResize = throttle(function () {
        handler();
    }, THROTTLE_WAIT);
    useEffect(function () {
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, [handler]);
};
