var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { borderRadius, boxShadow, display } from 'styled-system';
import { Button } from '../../common/components/Button';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { openSideNav, SideNavType } from '../../common/utils/navigation';
import { trackCategoriesButtonClick } from '../utils/tracking';
import { PrimaryNavigation } from './PrimaryNavigation';
var Wrapper = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), boxShadow);
var CategoriesButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, borderRadius);
var categoriesButtonStyles = {
    display: ['block', null, 'none'],
    borderRadius: 'x-large',
};
var TopNavigationBar = function (_a) {
    var _b = _a.navigation, navigation = _b === void 0 ? [] : _b, _c = _a.asButtonOnMobile, asButtonOnMobile = _c === void 0 ? false : _c, _d = _a.withBoxShadow, withBoxShadow = _d === void 0 ? true : _d, _e = _a.withArrows, withArrows = _e === void 0 ? false : _e, _f = _a.wrapItems, wrapItems = _f === void 0 ? false : _f, _g = _a.sideNavType, sideNavType = _g === void 0 ? SideNavType.Global : _g, shopName = _a.shopName;
    var t = useLocalization().t;
    if (navigation.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        asButtonOnMobile && (React.createElement(CategoriesButton, __assign({ kind: "secondary", onClick: function () {
                openSideNav({ type: sideNavType });
                trackCategoriesButtonClick(t('h24_categories'), shopName);
            } }, categoriesButtonStyles), t('h24_categories'))),
        React.createElement(Wrapper, __assign({}, (withBoxShadow && { boxShadow: 'bottom' })),
            React.createElement(PrimaryNavigation, { items: navigation, withArrows: withArrows, wrapItems: wrapItems, sideNavType: sideNavType, shopName: shopName }))));
};
export default TopNavigationBar;
var templateObject_1, templateObject_2;
